.radioGroup {
    margin-bottom: 20px;
}

.row {
    position: relative;
    margin-bottom: 4px;
    padding-bottom: 4px;
    cursor: pointer;

    &:last-child {
        margin-bottom: 0;
        border-bottom: none;
        &:after {
            display: none;
        }
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 3px;
        width: 98%;
        height: 1px;
        background-color: #eef2f6;
    }
}

.wrap {
    padding: 2px 6px 2px 2px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 12px;

    transition: background-color 0.2s ease-in-out;

    &:hover {
        background-color: #f8fafc;
    }
}
