.root {
    display: flex;
    align-items: center;
}

.badgeContainer {
    margin-right: 8px;
    padding: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8fafc;
    border: 1px solid #eef2f6;
    border-radius: 12px;
}

.cardImage,
.sbpImage {
    max-width: 24px;
    max-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    mix-blend-mode: multiply;
}

.sbpImage {
    max-width: 18px;
    max-height: 18px;
    margin-left: 4px;
}

.cardNumber {
    line-height: 20px;
    font-size: 14px;
}
